.sidebarMenu {
  background: var(--jello-color-background-base);
  border-right: 1px solid var(--jello-color-background-alternative);
  bottom: 0;
  padding-top: 15px;
  position: relative;
  width: 57px;
}

.btnContainer {
  bottom: 0;
  display: inline-block;
  position: absolute;
  text-align: center;
  width: 100%;
}

.btn:hover  {
  cursor: pointer;
}

.btn {
  background: var(--jello-color-background-base);
  border: none;
  display: inline-block;
  height: 40px;
  margin: 0 8px 8px 8px;
  padding: 0;
  text-align: center;
}

.btn:hover {
  background: var(--jello-color-background-alternative);
}

.btnExpanded  {
  background: var(--jello-color-background-alternative);
  border: none;
  float: right;
  margin-bottom: 3px;
  padding: 0;
  width: auto;
}

.displayed {
  min-width: 215px;
  width: fit-content;
}

.spacer {
  border-color: var(--jello-color-background-alternative);
  border-top: 1px solid var(--jello-color-background-alternative);
  margin: 0 8px 8px;
}