.generalInfoContainer {
  max-width: 560px;
  --vertical-spacing: var(--jello-spacing-large);
  --horizontal-spacing: var(--jello-spacing-medium);
  --column-width: calc(100% / 2 - var(--horizontal-spacing));
}

.generalInfoContainer jello-heading {
  margin-bottom: var(--jello-spacing-medium);
}

.generalInfoContainer section {
  margin-bottom: var(--jello-spacing-extra-large);
}

.blueIcon {
  color: var(--jello-color-foreground-highlight-base);
  margin: 0 4px;
}

.iconAndInfoRow {
  padding: 8px 0;
  display: flex;
  gap: 16px;
  align-items: flex-start;
  max-width: 500px;
}
