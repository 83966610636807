.wrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.title {
  color: var(--jello-color-interactive-active);
  font-size: var(--jello-font-size-medium);
  font-weight: var(--jello-font-weight-thick);
}

.h1 {
  margin: 0;
}

jello-chip {
  margin-inline-start: var(--jello-spacing-extra-large);
}
