.toolbarIconWrapper {
  display: flex;
  gap: 8px;
}

.toolbarDivider {
  width: 1px;
  height: 36px;
  background-color: var(--jello-color-background-alternative);
  margin: 0 16px;
}

.buttonsSection:not(:last-child) {
  border-right: 1px solid var(--jello-color-background-alternative);
  margin-right: 16px;
  padding-right: 16px;
}

.timeTracker {
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
