.pseudoCalendarButtonWrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.calendarNavBtn {
  background: none;
  border: none;
  padding: 0;
}

.calendarIconBtn {
  background: none;
  border: none;
  color: var(--jello-color-foreground-highlight-base);
  cursor: pointer;
  margin: 0;
  padding: 0 0 0 14px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.disabled {
  cursor: initial;
}

.calendarComponent {
  display: flex;
  align-items: center;
  height: var(--jello-font-size-large);
  border-radius: var(--jello-border-radius-huge);
  border: 1px solid var(--jello-color-foreground-highlight-base);
  border-color: var(--jello-color-foreground-highlight-base);
}

.calendarComponentDisabled {
  border-color: var(--jello-color-border-base);
}

.calendarComponentDisabled input {
  color: var(--jello-color-foreground-interactive-disabled) !important;
  -webkit-text-fill-color: var(--jello-color-foreground-interactive-disabled) !important;
}

.calendarComponent [class~=customArrowIcon] {
  font-size: 30px;
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.calendarComponent [class~=customArrowIcon] span {
  margin-bottom: 4px;
  color: var(--jello-color-foreground-highlight-base);
}
.calendarComponent [class~=DateInput_fang] {
  display: none;
}
.calendarComponent [class~=CalendarMonth_caption] {
  color: #0D79D6;
  font-size: 15px;
}
.calendarComponent [class~=DayPicker_weekHeaders]  {
  color: #9B9B9B;
}
.calendarComponent [class~=DayPickerNavigation_button]  {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: 30%;
}
.calendarComponent [class~=DateInput],
.calendarComponent [class~=DateRangePickerInput] {
  background: none;
  display: flex;
  height: 30px;
  margin: 0;
  padding: 0;
  width: auto;
}
.calendarComponent [class~=DateInput] input {
  background: none;
  cursor: pointer;
  display: flex;
  margin: 0;
  padding: 0;
  text-align: right;
  width: 100px;
  color: var(--jello-color-foreground-highlight-base);
}
.calendarComponent [class~=DateInput]:last-of-type  input {
  text-align: left;
}
.calendarComponent [class~=DateRangePickerInput_arrow] {
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.calendarComponent [class~=DateInput_input]  {
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border: none;
}
.calendarComponent [class~=DayPickerNavigation_button]:first-of-type  {
  left: -17px;
}
.calendarComponent [class~=DayPickerNavigation_button]:last-of-type  {
  right: -17px;
}
.calendarComponent [class~=DateRangePickerInput__disabled]  {
  background: none;
  color: #9B9B9B;
}
.calendarComponent [class~=CalendarMonth_table] tr {
  border-top: 2.5px solid #FFFFFF;
  border-bottom: 2.5px solid #FFFFFF;
}
.calendarComponent [class~=CalendarMonth_table] td {
  border: none;
}
.calendarComponent [class~=CalendarDay__selected_span] {
  background: #0D79D6;
  color: #FFFFFF;
}
.calendarComponent [class~=CalendarDay__selected_start],
.calendarComponent [class~=CalendarDay__selected_end],
.calendarComponent [class~=CalendarDay__selected_span] {
  background: #0D79D6;
  color: #FFFFFF;
}
.calendarComponent [class~=navBtnprev],
.calendarComponent [class~=navBtnnext] {
  position: relative;
}
.calendarComponent [class~=navBtnprev] span,
.calendarComponent [class~=navBtnnext] span {
  align-items: center;
  display: flex;
  justify-content: center;
}
