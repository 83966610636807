.listItem {
  list-style: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 12px 0;
}

.listItemText {
  margin-left: 8px;
  color: var(--jello-color-foreground-highlight-base);
}
