.chip {
  border-radius: var(--jello-border-radius-small);
  color: var(--jello-color-foreground-highlight-base);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  justify-content: left;
  margin: 0 8px 8px;
  padding: 8px;
  vertical-align: middle;
}

.chip:hover {
  background: var(--jello-color-background-alternative);
}

.selected, .selected:hover {
  background: var(--jello-color-foreground-highlight-base);
  color: var(--jello-color-foreground-inverse-base);
}

.selected, .chip:hover {
  font-weight: var(--jello-font-weight-thick);
}

.disabled, .disabled:hover {
  background: none;
  color: var(--jello-color-foreground-interactive-disabled);
  cursor: default;
}

.icon {
  float: left;
}

.icon:hover {
  border-radius: var(--jello-border-radius-small);
  float: left;
}

.tooltip {
  display: block;
}

.btn {
  text-decoration: none;
}

.btnHidden {
  display: none;
}