.list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin: 4px 16px;
}

.externalLink {
  text-decoration: none;
  color: var(--jello-color-foreground-highlight-base);
}

.liElement {
  list-style: none;
}
