.divider {
  width: 2px;
  height: var(--jello-size-small);
  width: 1px;
  border: none;
  border-right: 1px solid var(--jello-color-background-alternative);
}

.wrapperBlocks {
  display: flex;
  align-items: center;
}

.start {
  gap: 16px;
  z-index: 100;
  flex: 1;
}

.center {
  justify-content: center;
  flex: 2;
}

.end {
  gap: 16px;
  justify-content: end;
  flex: 1;
}

.bottomDivider {
  height: 1px;
  width: auto;
  background-color: var(--jello-color-background-alternative);
}

.dynamicItems {
  display: flex;
  gap: 8px;
  z-index: 25;
}

.displayFlex {
  display: flex;
}