.wrapper {
  display: flex;
  height: calc(100vh - 130px);
  width: 100%;
}

.periodSelectorWrapper {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.paddingCol {
  padding-bottom: 24px;
  min-height: 90%;
}

.paddingRow {
  padding-left: 20px;
  padding-bottom: 16px;
  padding-top: 16px;
}

.height {
  height: 100%;
}

.title {
  padding-left: 20px;
}

.positionRelative {
  position: relative;
  height: 100%;
}

.scroll {
  width: 100%;
  overflow-y: auto;
  background-color: var(--jello-color-background-alternative);
}
