.list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.list3El {
  flex-direction: column;
}

.externalLink {
  text-decoration: none;
  color: var(--jello-color-foreground-highlight-base);
}

.liElement {
  list-style: none;
}

.liElement1Column {
  width: 100%;
}

.liElement2Column {
  width: 50%;
}

.liElement3Column {
  width: 33%;
}

.liElement:hover {
  background-color: var(--jello-color-background-alternative);
}

.contentWrapper3El {
  width: 250px;
}

.contentWrapper6El {
  width: 500px;
}

.contentWrapper9El {
  width: 750px;
}

.contentWrapper10El {
  width: 750px;
  height: 260px;
}
