.jelloSearchBoxContainer {
    position: relative;
}

.jelloSearchBoxMinAlert {
    position: absolute;
    top: 48px;
    opacity: 0;
    left: 0;
    width: 250px;
    height: 24px;
    margin-inline-start: calc(5% + var(--jello-spacing-small));
    border-radius: 0 0 var(--jello-border-radius-medium) var(--jello-border-radius-medium);
    background: var(--jello-color-background-alternative);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--jello-spacing-small);
    line-height: var(--jello-spacing-small);
    transition: opacity 0.5s ease-in-out;
    z-index: 1;
}

.jelloSearchBoxMinAlert.show {
    opacity: 1;
}