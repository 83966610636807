.wrapper {
  list-style: none;
  display: flex;
  align-items: flex-start;
  margin: 12px 8px;
  cursor: pointer;
}

.wrapperText {
  display: flex;
  flex-direction: column;
}

.listItemText {
  margin-left: 8px;
  color: var(--jello-color-foreground-highlight-base);
}

.listItemDescription {
  margin-left: 8px;
  color: var(--jello-color-foreground-base);
}